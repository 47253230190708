<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="1200"
    @click:outside="clickOutside"
  >
    <template v-slot:activator="{ on, attrs }">
      <p id="payreqForm" v-bind="attrs" v-on="on" @click="openForm"></p>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5" style="margin: auto"
          >FORMULIR PAYMENT REQUEST</span
        >
      </v-card-title>
      <v-form>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Pemohon
                </p>

                <v-text-field
                  disabled
                  dense
                  outlined
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Perusahaan
                </p>

                <v-autocomplete
                  item-text="name"
                  item-value="id"
                  style="font-size: 12px; height: 45px"
                  return-object
                  dense
                  outlined
                  clearable
                  disabled
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Department
                </p>

                <v-autocomplete
                  style="font-size: 12px; height: 45px"
                  item-text="name"
                  item-value="id"
                  return-object
                  dense
                  outlined
                  clearable
                  disabled
                ></v-autocomplete>
              </v-col>
              <v-col md="3" class="d-flex flex-column"></v-col>

              <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Jenis Payment Request
                </p>

                <v-autocomplete
                  item-text="name"
                  item-value="id"
                  style="font-size: 12px; height: 45px"
                  return-object
                  dense
                  outlined
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="9" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Pilih Vendor
                </p>

                <v-autocomplete
                  item-text="name"
                  item-value="id"
                  style="font-size: 12px; height: 45px"
                  return-object
                  dense
                  outlined
                  clearable
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Jenis Pembayaran
                </p>

                <v-autocomplete
                  item-text="name"
                  item-value="id"
                  style="font-size: 12px; height: 45px"
                  return-object
                  dense
                  outlined
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Nama Bank
                </p>

                <v-text-field
                  dense
                  outlined
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  No. Rekening
                </p>

                <v-text-field
                  dense
                  outlined
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Atas nama
                </p>

                <v-text-field
                  dense
                  outlined
                  style="font-size: 12px; height: 45px"
                />
              </v-col>

              <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Sumber Dana
                </p>

                <v-text-field
                  dense
                  outlined
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="9" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Untuk Pembayaran
                </p>

                <v-text-field
                  dense
                  outlined
                  style="font-size: 12px; height: 45px"
                />
              </v-col>

              <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Pph/Ppn
                </p>
                <v-text-field
                  dense
                  outlined
                  type="number"
                  min="0"
                  style="height: 45px"
                />
              </v-col>
              <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Global Discount
                </p>

                <v-text-field
                  dense
                  outlined
                  type="number"
                  min="0"
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
            </v-row>

            <div style="margin-top: 20px">
              <v-data-table
                max-height="50vh"
                mobile-breakpoint="0"
                style="cursor: pointer; border: 1px solid rgba(0, 0, 0, 0.2)"
                :headers="payreqHeaders"
                :items="detailItems"
                :items-per-page="10"
                item-key="id"
              >
                <template v-slot:[`item.no`]="{ index }">
                  <div style="width: 35px">
                    {{ index + 1 }}
                  </div>
                </template>
                <template v-slot:[`item.product_code`]="{ item }">
                  <div style="width: 95px; font-size: 12px">
                    {{ item.product_code }}
                  </div>
                </template>
                <template v-slot:[`item.product_name`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        style="
                          text-align: left;
                          width: 250px;
                          font-size: 12px;
                          padding: 0;
                        "
                      >
                        {{
                          `${
                            item.product_code !== null
                              ? '(' + item.product_code + ')'
                              : ''
                          } ${item.product_name}`
                        }}
                      </div>
                    </template>
                    <span>
                      {{
                        `${
                          item.product_code !== null
                            ? '(' + item.product_code + ')'
                            : ''
                        } ${item.product_name}`
                      }}</span
                    >
                  </v-tooltip>
                </template>
                <template v-slot:[`item.vendor`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        style="
                          text-align: left;
                          width: 200px;
                          font-size: 12px;
                          padding: 0;
                        "
                      >
                        {{ item.vendor }}
                      </div>
                    </template>
                    <span>{{ item.vendor }}</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.fpb_qty`]="{ item }">
                  <div style="width: 45px; font-size: 12px">
                    {{ item.fpb_qty }}
                  </div>
                </template>
                <template v-slot:[`item.fpb_price`]="{ item }">
                  <div style="width: 75px; font-size: 12px">
                    {{ item.fpb_price }}
                  </div>
                </template>
                <template v-slot:[`item.uom`]="{ item }">
                  <div style="width: 35px; font-size: 12px">
                    {{ item.uom }}
                  </div>
                </template>
                <template v-slot:[`item.request_qty`]="{ item }">
                  <div style="width: 45px; font-size: 12px">
                    {{ item.request_qty }}
                  </div>
                </template>
                <template v-slot:[`item.price`]="{ item }">
                  <div style="width: 75px; font-size: 12px">
                    {{ item.price }}
                  </div>
                </template>
                <template v-slot:[`item.disc_percent`]="{ item }">
                  <div style="width: 45px; font-size: 12px">
                    {{ item.disc_percent }}
                  </div>
                </template>
                <template v-slot:[`item.disc_amount`]="{ item }">
                  <div style="width: 45px; font-size: 12px">
                    {{ item.disc_amount }}
                  </div>
                </template>
                <template v-slot:[`item.tax`]="{ item }">
                  <div style="width: 55px; font-size: 12px">
                    {{ item.tax }}
                  </div>
                </template>
                <template v-slot:[`item.total`]="{ item }">
                  <div style="width: 75px; font-size: 12px">
                    {{ item.total }}
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div style="padding: 0 25px 20px; width: 100%">
            <v-btn
              style="width: 100%"
              color="blue darken-1"
              class="white--text"
              @click="saveForm"
            >
              SIMPAN
            </v-btn>
            <v-btn
              style="width: 100%; margin-top: 15px"
              color="pink"
              class="white--text"
              @click="closeDialog"
            >
              BATAL
            </v-btn>
          </div>
          <!-- <v-btn color="blue darken-1" text type="submit"> Save </v-btn> -->
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'FormPayreq',
  props: [],
  data() {
    return {
      dialog: false,
      form: null,
      detailItems: []
    }
  },
  computed: {
    payreqHeaders() {
      const arrHeaders = [
        {
          text: 'No',
          value: 'no',
          align: 'left'
          // sortable: true,
        },
        {
          text: 'Kode Barang',
          value: 'product_code',
          align: 'left'
          // sortable: false,
        },
        {
          text: 'Nama Barang',
          value: 'product_name',
          align: 'left'
          // sortable: false,
        },
        {
          text: 'Vendor',
          value: 'vendor',
          align: 'left'
          // sortable: false,
        },
        {
          text: 'Qty Fpb',
          value: 'fpb_qty',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Harga FPB',
          value: 'fpb_price',
          align: 'left',
          sortable: false
        },
        {
          text: 'Unit',
          value: 'uom',
          align: 'left',
          sortable: false
        },
        {
          text: 'Qty Request',
          value: 'request_qty',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Harga',
          value: 'price',
          align: 'left',
          sortable: false
        },
        {
          text: 'Disc %',
          value: 'disc_percent',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Disc Amount',
          value: 'disc_amount',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Tax',
          value: 'tax',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Total',
          value: 'total',
          align: 'left',
          // sortable: true,
          sortable: false
        }
      ]
      // if (this.detail.status.id === 0) {
      return arrHeaders
      // }
    }
  },
  mounted() {},
  methods: {
    onResize() {
      this.wWidth = window.innerWidth
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    openForm() {
      console.log('hallo world')
      this.detailItems = [
        ({
          no: 1,
          product_name: 'LAPTOP DELL RYZEN 5',
          product_code: 'AST-0016',
          vendor: 'TOKOPEDIA',
          fpb_qty: '5',
          fpb_price: '5.600.000',
          uom: 'Unit',
          request_qty: 3,
          price: '5.300.000',
          disc_percent: 0,
          disc_amount: 0,
          tax: 0,
          total: '15.900.000'
        },
        {
          no: 2,
          product_name: 'RAM DDR 4',
          product_code: 'AST-3112',
          vendor: 'TOKOPEDIA',
          fpb_qty: '3',
          fpb_price: '210.000',
          uom: 'Pcs',
          request_qty: 3,
          price: '210.000',
          disc_percent: 0,
          disc_amount: 0,
          tax: 0,
          total: '630.000'
        })
      ]
    },
    // clear() {
    //   this.$emit('loadPage')
    // },
    clickOutside() {
      //   this.closeDialog()
    },
    closeDialog() {
      setTimeout(() => {
        this.dialog = false
      }, 200)
    },
    saveForm() {
      setTimeout(() => {
        this.dialog = false
      }, 200)
    }
  }
}
</script>
